import styles from "./LegalInformation.module.scss";

function LegalInformation() {
  return (
    <div className={styles.LegalInformation}>
      <div className={styles.container}>
        <h1>Правовая информация</h1>
        <div className={styles.text}>
          <p>
            Правообладателем ИС «Остарбайтеры», включая входящую в ее состав
            электронную базу данных архивных документов, является авторский
            коллектив проекта «Перемещение советских граждан на принудительные
            работы в нацистскую Германию (1941-1943 гг.): база данных и
            интерактивная карта (на материалах Ростовской области)»,
            реализованного в рамках гранта Российского научного фонда №
            23-28-01114 (В.А. Агеева, Н.А. Трапш, М.И. Жбан-никова, А.А.
            Асташев.)
          </p>
          <p>
            Собственниками архивных документов, электронные копии которых
            размещены в ИС «Остарбайтеры», являются Российская Федерация (в
            части документов, предоставленных российскими государственными
            архивами), а также иностранные государственные архивы,
            предоставившие электронные копии собственных документов.
          </p>
          <p>
            Все сервисы, а также информационный контент, предоставляемые ИС
            «Остарбайтеры», являются информационными услугами, оказываемыми
            бесплатно.
          </p>
          <p>
            Обязательным условием использования документов, размещенных в ИС
            «Остарбайтеры», является ссылка на сайт.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LegalInformation;
