import FormModuleHomePage from "../../modules/HomePageFormModule/FormModuleHomePage";
import SliderModule from "../../modules/SliderModule/SliderModule";
import styles from "./HomePage.module.scss";

function HomePage() {
  return (
    <div className={styles.HomePage}>
      <div className={styles.HomePage__firstBlock}>
        <FormModuleHomePage />
        <SliderModule />
      </div>
      <img className={styles.sliderImg} src="./img/sliderImg.png" alt="bg" />
      <img className={styles.sliderImg2} src="./img/sliderImg2.png" alt="bg" />
      <img className={styles.HomePage__bg1} src="./img/bgMain1.png" alt="bg" />
      <img
        className={styles.BgAboutHomeLeft}
        src="./img/BgAboutHomeLeft.png"
        alt="bg"
      />
      <div className={styles.about}>
        <div className={styles.about_inner} id="about">
          <h3>О проекте</h3>
          {/* <p>
            С мая 2015 года работает информационная система «Память народа»
            (электронный паспорт зарегистрирован на официальном сайте
            Федеральной государственной информационной системы координации
            информации (ФГИС КИ)). Система объединяет все ранее созданные нами
            общедоступные банки данных «Мемориал» и «Подвиг народа в Великой
            Отечественной войне 1941–1945 годов», содержащие сведения об
            участниках Великой Отечественной войны, вернувшихся с фронтов
            Победителями, о погибших и пропавших без вести, их подвигах и
            награждениях. Все сведения объединены в единую информационную
            интерактивную систему с возможностью подбора документов и
            восстановления истории службы воина.
          </p> */}
          <p>
            Проект«Перемещение советских граждан на принудительные работы в
            нацистскую Германию (1941-1943 гг.): база данных и интерактивная
            карта (на материалах Ростовской области)» реализован в рамках гранта
            Российского научного фонда № 23-28-01114 научным коллективом:
          </p>
          {/* <h5>В результате выполненной работы ИС «Память народа» содержит:</h5> */}
          <ul>
            <li>
              декан факультета истории и филологии Таганрогского института имени
              А.П. Чехова (филиала) РГЭУ (РИНХ), кандидат исторических наук,
              доцент Валентина Анатольевна Агеева - руководитель проекта;
            </li>
            <li>
              директор Государственного архива Ростовской области, кандидат
              исторических наук,доцент Николай Алексеевич Трапш;
            </li>
            <li>
              ведущий археограф Государственного архива Ростовской области
              Марина Исматовна Жбанникова;
            </li>
            <li>
              студент факультета истории и филологии Таганрогского института
              имени А.П. Чехова (филиала) РГЭУ (РИНХ) Алексей Александрович
              Асташев.
            </li>
          </ul>
          <p>
            В ходе Великой Отечественной войны значительная часть Ростовской
            области длительный период была оккупирована немецко-фашистскими
            захватчиками. Нацистская оккупация началась осенью 1941 года и для
            отдельных районов завершилась только в августе 1943 года. В
            оккупационный период более 85 тысяч мирных жителей были угнаны в
            Германию для принудительной трудовой деятельности.
          </p>
          <p>
            Основная цель предлагаемого проекта – последовательное сохранение
            исторической памяти о гражданском населении Дона, пострадавшем от
            преступной деятельности нацистского режима, и документальное
            обеспечение исследовательской работы, связанной с интеллектуальным
            противодействием историческим фальсификациям, относящимся к
            отдельным событиям и явлениям Великой Отечественной войны.
          </p>
          <p>
            В интерактивной базе данных представлена консолидированная
            информация, касающаяся 57 тысяч перемещенных граждан СССР,размещено
            в открытом доступе порядка 600 листов архивных документов,
            выявленных в отечественных и зарубежных архивах. Информационная
            система предусматривает технологическую возможность дальнейшего
            пополнения фактическим материалом, полученным в результате
            эвристической работы в профильных архивах и исследовательской
            деятельности.
          </p>
          <p>
            Интерактивная картасодержит географические и статистические сведения
            о насильственно перемещенных жителях Ростовской области,
            занимавшихся принудительной трудовой деятельностью на территории
            нацистской Германии в годы Великой Отечественной войны.
          </p>
        </div>
      </div>
      <img
        className={styles.BgAboutHomeRight}
        src="./img/BgAboutHomeRight.png"
      />
    </div>
  );
}

export default HomePage;
